import { createStore as _createStore, applyMiddleware, compose } from 'redux';
import persistState from 'redux-localstorage';
import createMiddleware from './middleware/clientMiddleware';
import transitionMiddleware from './middleware/transitionMiddleware';
import reducer from './modules/reducer';

function stateSlice() {
  return state => {
    const subset = {
      auth: {
        selectedRoot: state.auth.selectedRoot,
        selectedOrg: state.auth.selectedOrg,
        user: state.auth.user,
        trackerAuthToken: state.auth.trackerAuthToken,
      },
      outreach: {
        ...state.outreach,
        mentors: [],
        mentees: [],
        prioritizedMentees: [],
        archivedMentees: [],
        outreachHistoryModal: false,
      },
      menteeOutreach: {
        ...state.menteeOutreach,
        mentees: [],
        prioritizedMentees: [],
        archivedMentees: [],
        targetProfiles: {},
        peerscheduledMentees: [],
        outreachListStats: {
          fullList: 0,
          archiveList: 0,
          peerSchedulerList: 0,
        },
      },
      menteeMasterFollowUp: {
        openProfileTabs: {},
      },
      engagedParty: {
        ...state.engagedParty,
        engagedPartyList: [],
        archivedEngagedPartyList: [],
      },
      engagedSupport: {
        ...state.engagedSupport,
        esList: [],
        menteeProfile: {},
        esMentorList: [],
        latestCallTx: null,
        enrollmentCallTx: null,
        openIssue: false,
        profileLoading: true,
        loading: true,
        callFooter: null,
      },
      portal: {
        ...state.portal,
        listLoading: false,
        list: [],
        data: [],
        issueDetailTab: null,
      },
      clientPortal: {
        loading: true,
        allAvailableReportsList: [],
        monthlyAvailabeReportsList: [],
        selectedIntervalReport: null,
      },
      outboundTelephony: {
        activeDeviceInstance: {},
        activeCallConnection: {},
        callSid: '',
        conferenceId: null,
        conferencingOptions: [],
        externalEntityTxId: null,
        outboundCallInProgress: [],
        outboundCallUserProperties: [],
        showCallCompleteModal: false,
        showCallStatusModal: false,
        currentCallStartTime: null,
        callStatusUpdatedForCurrentCall: true
      },
      activeInboundCallQueue: {
        ...state.activeInboundCallQueue,
        stats: {},
        readyToAcceptCallFromQueue: 1,
        showPostCallModal: false,
      },
      tasks: {
        taskUsers: [],
        taskEnums: []
      }
    };
    return subset;
  };
}

export default function createStore(client, data) {
  const middleware = [createMiddleware(client), transitionMiddleware];
  const finalCreateStore = compose(
    applyMiddleware(...middleware),
    persistState(null, {
      slicer: stateSlice,
    }),
  )(_createStore);
  return finalCreateStore(reducer, data);
}
