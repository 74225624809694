import { combineReducers } from 'redux';

import accountSignup from './mentor/accountSignup';
import activeInboundCallQueue from './activeInboundCallQueue'
import administrativeTask from './administrativeTask';
import adminMentorProfile from './mentor/adminMentorProfile';
import alarm from './alarms/alarm';
import alerts from './alerts';
import analytics from './analytics';
import auth from './auth';
import callReviews from './callReviews';
import chat from './chat';
import clientPortal from './clientPortal/clientPortal';
import cms from './cms';
import cmsIssueArea from './cms/cmsIssueArea';
import cmsIssueTrackStep from './cms/cmsIssueTrackStep';
import cmsIssueGuider from './cms/cmsIssueGuider';
import cmsRecipe from './cms/cmsRecipe';
import cmsMinimodule from './cms/cmsMinimodule';
import cmsPaperCollateral from './cms/cmsPaperCollateral';

import cmsSurvey from './cmsSurvey';
// import commandCenter from './commandCenter';
import commandCenter from './commandCenter/commandCenter';
import common from './common';
import communication from './communication';
import coordinator from './coordinator';
import copilot from './copilot/copilot';
import copilotTalkingPoint from './copilot/copilotTalkingPoint';
import dashboard from './dashboard';
import conversionFunnel from './dashboard/conversionFunnel';
import engagedParty from './engagedParty';
import engagedSupport from './engagedSupport';
import enroll from './enroll';
import esGoal from './mentee/esGoal';
import flexTools from './flexTools/flexTools';
import mentorDashboard from './mentor/mentorDashboard';
import esIssues from './issue/issues';
import esMenteeListFilters from './mentee/esMenteeListFilters'; 
import esOverview from './mentee/esOverview';
import externalEntityTx from './externalEntityTx'
import general from './general';
import goals from './goals';
import goalReviews from './goalReviews/goalReviews'
import goalReviewsSummary from './goalReviews/goalReviewsSummary'
import inboundCallQueue from './inboundCallQueue';
import issues from './issues';
import issueArea from './issue/issueArea';
import inboundTelephony from './telephony/inboundTelephony';
import ivr from './ivr';
import keyIssues from './keyIssues';
import logisticalIssues from './logisticalIssues';
import mentee from './mentee';
import menteeActionPlan from './mentee/menteeActionPlan'; 
import menteeMasterFollowUp from './menteeMaster/menteeMasterFollowUp';
import menteeOutreach from './menteeOutreach';
import menteeSOP from './mentee/menteeSOP';
import mentor from './mentor';
import mentorSOP from './mentor/mentorSOP';
import mentorSurvey from './mentor/mentorSurvey';
import todoList from './mentor/todoList';
import mentoringProgram from './mentoringProgram';
import mentoringProgramSummary from './mentoringProgramSummary';
import modal from './modal';
import outreach from './outreach';
import partnerMenteeMessaging from './ccp/partnerMenteeMessaging';
import peerbotCommands from './peerbot/peerbotCommands';
import peerNavigator from './peerNavigator/peerNavigator';
import peerNavigatorCallScheduling from './peerNavigator/peerNavigatorCallScheduling';
import pnCopilot from './copilot/pnCopilot';
import prospectReferral from './outreach/prospectReferral';
import outreachConsole from './outreach/console';
import pusher from './pusher/pusher'
import outreachDashboard from './outreachDashboard';
import nav from './nav';
import notes from './notes';
import esMentorPayment from './esMentorPayment';
import pmCommunity from './pmCommunity';
import pmu from './pmu';
import portal from './portal';
import recordingUploader from './utility/recordingUploader';
import screen from './screen';
import sequence from './sequence';
import sop from './sop';
import supporter from './supporter/supporter';
import supporterCopilot from './copilot/supporterCopilot';
import telephony from './telephony';
import userManager from './userManager/userManager';
import voicemailInbox from './voicemailInbox';
import outboundTelephony from './telephony/outboundTelephony';
import userCompensation from './compensation/userCompensation';

import tasks from './tasks';

const appReducer = combineReducers({
  accountSignup,
  activeInboundCallQueue,
  administrativeTask,
  adminMentorProfile,
  alarm,
  alerts,
  analytics,
  auth,
  callReviews,
  chat,
  clientPortal,
  cms,
  cmsIssueArea,
  cmsIssueTrackStep,
  cmsIssueGuider,
  cmsMinimodule,
  cmsPaperCollateral,
  cmsRecipe,
  cmsSurvey,
  commandCenter,
  common,
  communication,
  conversionFunnel,
  coordinator,
  copilot,
  copilotTalkingPoint,
  dashboard,
  engagedParty,
  engagedSupport,
  enroll,
  esGoal,
  esIssues,
  esMenteeListFilters,
  esOverview,
  externalEntityTx,
  flexTools,
  general,
  goals,
  goalReviews,
  goalReviewsSummary,
  inboundCallQueue,
  issues,
  issueArea,
  ivr,
  inboundTelephony,
  keyIssues,
  logisticalIssues,
  mentee,
  menteeActionPlan,
  menteeMasterFollowUp,
  menteeOutreach,
  menteeSOP,
  mentor,
  mentorDashboard,
  mentorSOP,
  mentorSurvey,
  mentoringProgram,
  mentoringProgramSummary,
  modal,
  nav,
  notes,
  outreach,
  outreachDashboard,
  outboundTelephony,
  esMentorPayment,
  partnerMenteeMessaging,
  peerbotCommands,
  peerNavigator,
  peerNavigatorCallScheduling,
  pnCopilot,
  pmCommunity,
  pmu,
  portal,
  prospectReferral,
  outreachConsole,
  pusher,
  recordingUploader,
  sequence,
  screen,
  sop,
  supporter,
  supporterCopilot,
  telephony,
  userCompensation,
  userManager,
  todoList,
  voicemailInbox,
  tasks
});

const rootReducer = (state, action) => {
  if (action.type === 'inquisit-app/auth/LOGOUT_SUCCESS') {
    // On logout, reset the entire store to its default state
    state = {userManager: state.userManager};
  }

  return appReducer(state, action);
};

export default rootReducer;
