const ACTIVE_CALL_QUEUE_LOAD_REQUESTED = 'inquisit-app/ACTIVE_CALL_QUEUE/ACTIVE_CALL_QUEUE_LOAD_REQUESTED';
const ACTIVE_CALL_QUEUE_GENERIC_SUCCESS = 'inquisit-app/ACTIVE_CALL_QUEUE/ACTIVE_CALL_QUEUE_GENERIC_SUCCESS';
const ACTIVE_CALL_QUEUE_REQUEST_FAIL = 'inquisit-app/ACTIVE_CALL_QUEUE/ACTIVE_CALL_QUEUE_REQUEST_FAIL';

const SET_CALL_STATS = 'inquisit-app/ACTIVE_CALL_QUEUE/SET_CALL_STATS';
const TOGGLE_POST_CALL_MODAL = 'inquisit-app/ACTIVE_CALL_QUEUE/TOGGLE_POST_CALL_MODAL';
const TOGGLE_INBOUND_POST_CALL_MODAL = 'inquisit-app/ACTIVE_CALL_QUEUE/TOGGLE_INBOUND_POST_CALL_MODAL';
const READY_FOR_INBOUND_CALL_QUEUE_SUCCESS = 'inquisit-app/ACTIVE_CALL_QUEUE/READY_FOR_INBOUND_CALL_QUEUE_SUCCESS';

const initialState = {
    stats: [],
    readyToAcceptCallFromQueue: 1, 
    showPostCallModal: false,
    showInboundPostCallModal: false,
};

// const GENERIC = [ACTIVE_CALL_QUEUE_LOAD_REQUESTED, ACTIVE_CALL_QUEUE_GENERIC_SUCCESS, ACTIVE_CALL_QUEUE_REQUEST_FAIL];

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CALL_STATS: 
        return {
            ...state,
            stats: action.data
        }
    case READY_FOR_INBOUND_CALL_QUEUE_SUCCESS:
      return {
        ...state,
        readyToAcceptCallFromQueue: action.data,
      }
    case TOGGLE_POST_CALL_MODAL: 
      return {
        ...state,
        showPostCallModal: action.data,
      }
    case TOGGLE_INBOUND_POST_CALL_MODAL: 
      return {
        ...state,
        showInboundPostCallModal: action.data,
      }
    default:
      return state;
  }
}

export function setCallStats(value) {
    return {
      type: SET_CALL_STATS,
      data: value
    };
}

export function togglePostCallModal(value) {
  return {
    type: TOGGLE_POST_CALL_MODAL,
    data: value
  };
}

export function toggleInboundPostCallModal(value) {
  return {
    type: TOGGLE_INBOUND_POST_CALL_MODAL,
    data: value
  };
}

export function toggleReadyForCallQueue(value) {
  return {
    type: READY_FOR_INBOUND_CALL_QUEUE_SUCCESS,
    data: value
  };
}

export function getStatusInfoForPostCallModal(data) {
  return {
    types: [ACTIVE_CALL_QUEUE_LOAD_REQUESTED,ACTIVE_CALL_QUEUE_GENERIC_SUCCESS,ACTIVE_CALL_QUEUE_REQUEST_FAIL],
    promise: (client) => client.get('/inbound_call_queue/get_inbound_queue_call_statuses', {
      data: data
    })
  };
}

export function updateCallStatus(data) {
  return {
    types: [ACTIVE_CALL_QUEUE_LOAD_REQUESTED,ACTIVE_CALL_QUEUE_GENERIC_SUCCESS,ACTIVE_CALL_QUEUE_REQUEST_FAIL],
    promise: (client) => client.post('/inbound_call_queue/update_inbound_queue_call_status', {
      data: data
    })
  };
}

// export function joinExistingIvrCycle(ivrCycleInmemId) {
//   return {
//     types: GENERIC,
//     promise: (client) => client.post('/setivr/join_existing_ivr_cycle', {
//       data: {
//         ivr_cycle_inmem_id: ivrCycleInmemId,
//         et_ind: 1
//       }
//     })
//   }
// }

