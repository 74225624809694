import { FirebaseTrackerHelper } from 'helpers/FirebaseTrackerHelper';
import { logoutPreserveRoute } from 'helpers/LogoutHelper';

let oldHash = '';
export default () => next => action => {
	if (oldHash !== window.location.hash) {
	  	if (FirebaseTrackerHelper.trackRoute) FirebaseTrackerHelper.trackRoute();	
	}
	oldHash = window.location.hash;
	if (action.error && (action.error.status === 401 || (action?.error?.error?.includes && action?.error?.error?.includes('AUTHE02: Not Authenticated')) || (action?.error?.includes && action?.error?.includes('AUTHE02: Not Authenticated')))) {
		if (window.location.hash !== '#/' && window.location.hash !== '#/login' && window.location.hash !== '#/authentication/callback' && window.location.hash !== '/authentication/callback') {
	  		logoutPreserveRoute();
		} else {
			return false;
		}
  	}
  	return next(action);
};
