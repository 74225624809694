const GENERIC_REQUEST = 'inquisit-app/tasks/GENERIC_REQUEST';
const GENERIC_SUCCESS = 'inquisit-app/tasks/GENERIC_SUCCESS';
const GENERIC_FAIL = 'inquisit-app/tasks/GENERIC_FAIL';

const SEARCH_SUCCESS = 'inquisit-app/tasks/SEARCH_SUCCESS';
const SEARCH_FAIL = 'inquisit-app/tasks/SEARCH_FAIL';

const PYX_TASK_USERS_SUCCESS = 'inquisit-app/tasks/PYX_TASK_USERS_SUCCESS';
const PYX_TASK_ENUMS_SUCCESS = 'inquisit-app/tasks/PYX_TASK_ENUMS_SUCCESS';

const initialState = {
  taskUsers: [],
  taskEnums: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PYX_TASK_USERS_SUCCESS:
      return {
        ...state,
        taskUsers: action.result
      };
    case PYX_TASK_ENUMS_SUCCESS:
      return {
        ...state,
        taskEnums: action.result
      };
    default:
      return state;
  }
}

/*
  *******
  *******
   Get
  *******
  *******
  */

export function getPyxTasksSearch(data) {
  return {
    types: [GENERIC_REQUEST, SEARCH_SUCCESS, SEARCH_FAIL],
    promise: (client) => client.post(':pyxapi/v5/Tasks/search', {
      data: data
    })
  };
}

export function getPyxTasksUsers() {
  return {
    types: [GENERIC_REQUEST, PYX_TASK_USERS_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get(':pyxapi/v5/Tasks/users')
  };
}

export function getPyxTasksEnums() {
  return {
    types: [GENERIC_REQUEST, PYX_TASK_ENUMS_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get(':pyxapi/v5/Tasks/enums')
  };
}

export function getPyxTaskDetails(rootId, taskId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.get(`:pyxapi/v5/Tasks/${rootId}:${taskId}`)
  };
}

/*
  *******
  *******
   Set
  *******
  *******
  */

export function assignPyxTask(rootId, taskGuid, userId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.put(`:pyxapi/v5/Tasks/${rootId}:${taskGuid}/assign`, {
      data: { assignedToUserId: userId }
    })
  };
}

export function createPyxTask(taskData) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post(':pyxapi/v5/Tasks', {
      data: taskData
    })
  };
}

export function createPyxCallLog(callLogData) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post(':pyxapi/v1/CallLogs', {
      data: callLogData
    })
  };
}

export function updatePyxCallLog(organizationId, callLogGuid, callLogData) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.put(':pyxapi/v1/CallLogs/' + organizationId + ':' + callLogGuid, {
      data: callLogData
    })
  };
}

export function assignPyxTaskToUser(organizationId, taskGuid, agentUserId) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.put(`:pyxapi/v5/Tasks/${organizationId}:${taskGuid}/assign`, {
      data: { assignedToUserId: agentUserId }
    })
  };
}

export function associatePyxCallLogWithTask(organizationId, taskGuid, callLogGuid) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post(`:pyxapi/v5/Tasks/${organizationId}:${taskGuid}/CallLog`, {
      data: callLogGuid
    })
  };
}

export function addPyxTaskActivity(organizationId, taskGuid, activityData) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post(`:pyxapi/v5/Tasks/${organizationId}:${taskGuid}/activity`, {
      data: activityData
    })
  };
}

export function closePyxTask(organizationId, taskGuid, closeReason) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.put(`:pyxapi/v5/tasks/${organizationId}:${taskGuid}/complete`, {
      data: { closeReason }
    })
  };
}

export function getPyxTasksPeerCallList(data) {
  return {
    types: [GENERIC_REQUEST, GENERIC_SUCCESS, GENERIC_FAIL],
    promise: (client) => client.post(':pyxapi/v5/Tasks/PeerCallList', {
      data: data
    })
  };
}
